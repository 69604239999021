/**
   * 存储localStorage
   */
export const setStore = (name, content) =>{
  if (!name) return
  window.localStorage.setItem(name, content);
}
/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return ''
  return window.localStorage.getItem(name) || ''
}
/**
 * 清除localStorage
 */
 export const clearStore = () => {
  window.localStorage.clear();
}

export const removeItem = (key) => {
  window.localStorage.removeItem(key)
}

export const getQueryStringByName = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  var context = "";
  if (r != null)
    context = r[2];
  reg = null;
  r = null;
  return context == null || context === "" || context === "undefined" ? "" : context;
}

export const resetRoute = () => (window.location.href = window.location.origin);

export const  splitArray = (array, subGroupLength) => {
  let index = 0
  let newArray = []
  while(index < array.length) {
      newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}

export const loadScript = ( url, callback ) => {
  var script = document.createElement('script'),
      fn = callback || function(){};
  script.type = 'text/javascript'
  script.crossOrigin = 'anonymous'
  script.async = true
  script.defer = true
  if(script.readyState){
    script.onreadystatechange = () => {
      if( script.readyState === 'loaded' || script.readyState === 'complete' ){
        script.onreadystatechange = null;
        fn('success');
      }
    }
  }else{
    //其他浏览器
    script.onload = () => {
      fn('success');
    };
    script.onerror = () => {
      fn('fail');
    }
  }
  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
}

export const isLegalEmail = (email) => {
  //
  let checkEmail = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/
  // /^\w+(?:\.\w+){0,1}@@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/
  // console.log(checkEmail.test(email))
  return checkEmail.test(email)
}

export const isLegalChinaPhone = (num) => {
  // let zhCNRegular = /^((13[0-9])|(14[5,7,9])|(15[0-3,5-9])|(166)|(17[3,5,6,7,8])" +
  // "|(18[0-9])|(19[8,9]))\\d{8}$/
  let zhCNReg = /^(\+?0?86?)?1[345789]\d{9}$/
  return zhCNReg.test(num)
}

// 合法的香港手机号
export const isLegalHKPhone = (num) => {
  // let HKReg = /^(\+?0?86\-?)?1[345789]\d{9}$/
  // /^(\+852)[6|9]\d{7}$/
  // /^(\+852)(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/
  // http://www.qilin668.com/a/5e53d0c43a2852k.html
  let HKReg = /^(\+852)?(5[1234569]\d{6}|6\d{7}|9[0-8]\d{6})$/
  return HKReg.test(num)
}

export const isLegalHKID = (str) => {
  // console.log(/^((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}((\([0-9aA]\))|([0-9aA]))$/.test('A123456(A)'))
  // console.log(/^[A-Z]\d{6}\([\dA]\)$/.test('A1234560'))
  return /^((\s?[A-Z])|([A-Z]{2}))\d{6}((\([0-9A]\))|([0-9A]))$/.test(str)
  // 規則校驗
  // 合法Id: G123456(A),AB987654(3)
  // if (!/^[A-Z]{1,2}\d{6}\([\dA]\)$/.test(str)) {
  //   return false;
  // }

  // let newHkId = str.replaceAll("(", "").replaceAll(")", "");
  // let newIdLen = newHkId.length;

  // if (newIdLen !== 8 && newIdLen !== 9) {
  //     return false;
  // }

  // let newIdNoDigit = newHkId.substring(0, newIdLen -1);
  // let newIdNoDigitLen = newIdNoDigit.length;
  // let newIdArray = newIdNoDigit.split('');

  // let checkSum = 0,
  //     startIdx = 0,
  //     stepNum = 9,
  //     minusNum = 55;

  // // 初始化
  // if (newIdNoDigitLen == 7) {
  //     // 空格固定36
  //     checkSum = 36 * stepNum--;
  // } else if (newIdNoDigitLen === 8) {
  //     checkSum = (newIdArray[0].charCodeAt() - minusNum) * stepNum--;
  //     startIdx = 1;
  // }

  // for (let idx = startIdx; idx < newIdNoDigitLen; idx++) {
  //     if (idx !== startIdx) {
  //         checkSum += Number(newIdArray[idx]) * stepNum--;
  //     } else {
  //         checkSum += (newIdArray[idx].charCodeAt() - minusNum) * stepNum--;
  //     }
  // }

  // let checkDigit = 11 - checkSum%11;
  // let newCheckDigit;
  // if (checkDigit === 10) {
  //     newCheckDigit = 'A';
  // } else if (checkDigit === 11) {
  //     newCheckDigit = '0';
  // } else {
  //     newCheckDigit = checkDigit + '';
  // }

  // if (newCheckDigit.charCodeAt() === newHkId.substring(newIdNoDigitLen).charCodeAt()) {
  //     return true;
  // } else {
  //     return false;
  // }
}

// 合法的香港手机号和固定电话
export const isLegalHKCommunication = (num) => {
  let HKReg = /^(852)?((2[1-9]|3[145679]|5[1234569]9[0-8])\d{6}|6\d{7})$/
  return HKReg.test(num)
}
export const setFontSize = (size, scale) => {
  return {
    fontSize: parseInt(size*scale) + 'px'
  }
}

export const updateFontSize = (element, scale) => {
  if (element) {
    for(var i = 0; i < element.children.length; i++){
      const el = element.children[i]
      if (el.children.length === 0) {
        if (getComputedStyle(el)){
          const fontSize = getComputedStyle(el)["font-size"]
          const size = fontSize.slice(0, fontSize.length - 2)
          if (!el.style.originFontSize) {
            el.style.originFontSize = size
            el.style.fontSize = parseInt(size * scale) + 'px'
          } else {
            el.style.fontSize = parseInt(el.style.originFontSize * scale) + 'px'
          }
        }
      } else {
        updateFontSize(el, scale)
      }
    }
  }
}

export const supportSticky = () => {
  let sticky
  if (CSS.supports("position", "sticky")){
    sticky = {position: 'sticky'}
  } else if (CSS.supports("position", "-webkit-sticky")) {
    sticky = {position: '-webkit-sticky'}
  } else if (CSS.supports("position", "-moz-sticky")) {
    sticky = {position: '-moz-sticky'}
  } else if (CSS.supports("position", "-o-sticky")) {
    sticky = {position: '-o-sticky'}
  } else if (CSS.supports("position", "-ms-sticky")) {
    sticky = {position: '-ms-sticky'}
  }
  return sticky
}

export const generateRangeNumber = (start, end) => {
  let range = []
  for (let i = Number(start); i <= Number(end); i++) {
    range.push(i+'')
  }
  return range
}

export const generateRangeNumberPicker = (start, end) => {
  let range = []
  for (let i = Number(start); i <= Number(end); i++) {
    range.push({
      value: i+'',
      label: i+''
    })
  }
  return [range]
}

/*将100000转为100,000形式*/
export const  dealNumber = (money) => {
  if(money && money!=null){
    money = String(money);
    var left = money.split('.')[0],right=money.split('.')[1];
    right = right ? (right.length >= 2 ? '.' + right.substr(0, 2) : '.'+right+'0') : ''; //不需要.00
    var temp = left.split('').reverse().join('').match(/(\d{1,3})/g);
    return (Number(money)<0?"-":"") + temp.join(',').split('').reverse().join('')+right;
  }else if(money===0){   //注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
    return '0'
  }else{
    return "";
  }
}

export const parseUrlParam = (str) => {
  const search = str.substring(1)
  const params = {}
  search.split('&').forEach((item) => {
    let itemArr = item.split('=')
    params[itemArr[0]] = itemArr[1]
  })
  return params
}

// export const formatRichtextImage = (richtext, imgWidth) => {
//   if (!richtext) {
//     return richtext
//   }

//   var imgReg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;
//   var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
//   // pc端默认宽度1110
//   var width = imgWidth ? imgWidth : '660';

//   richtext = richtext.replace(imgReg, (item, capture) => {
//     var orginArray = item.match(srcReg)
//     var orginSrc = orginArray[0]
//     var orginImgUrl = orginArray[1]
//     var ossIndex = orginImgUrl.indexOf("?x-oss-process=image")
//     ossIndex = ossIndex < 0 ? orginImgUrl.length : ossIndex

//     var result = item.substr(0, item.indexOf(orginSrc)) +
//     'src="' + orginImgUrl.substr(0, ossIndex) + '?x-oss-process=image/resize,w_' + width +  '"' +
//     item.substr(item.indexOf(orginSrc) + orginSrc.length, item.length);

//     return result;
//   })
//   return richtext;
// }

//密码必须包括数字，小写英文，大写英文
export const  isLegalPassword = (password) => {
  // let reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]$/;
  // return reg.test(password)
  let ls = 0
  if(password.match(/([a-z])+/)){ ls++ }  
  if(password.match(/([0-9])+/)){ ls++ }  
  if(password.match(/([A-Z])+/)){ ls++ }  
  return ls === 3
}
//滚动条距离顶部高度
export const getScrollTop = () => {
  var scrollTop=0
  if(document.documentElement&&document.documentElement.scrollTop) {
    scrollTop=document.documentElement.scrollTop;
  }
  else if(document.body)  {
    scrollTop= document.body.scrollTop;
  }
  return  Math.ceil(scrollTop);
}

//滚动条本身高度：就是可视窗口高度
export const getScrollBarHeight = () => {
  let scrollBarHeight = document.documentElement.clientHeight;
  return Math.ceil(scrollBarHeight);
}

//整个页面高度
export const getPageHeight = () => {
  return Math.ceil(Math.max(document.body.clientHeight,document.documentElement.scrollHeight));
}
// 获取居住地
export const getResidency = (value) => {
  let residency = ''
  switch (value) {
    case 'CN':
      residency = 'common.residency.cn'
      break;
    case 'HK':
      residency = 'common.residency.hk'
      break;
    case 'MO':
      residency = 'common.residency.mo'
      break;
    case 'OTHER':
      residency = 'common.other'
      break;
    default:
      break;
  }
  return residency
}

// 获取缴费方式
export const getPaymentPlan = (value = '') => {
  let paymentPlan = ''
  switch (value) {
    case 'ANNUAL':
      paymentPlan = 'common.paymentPlan.ANNUAL'
      break;
    case 'SEMI_ANNUAL':
      paymentPlan = 'common.paymentPlan.SEMI_ANNUAL'
      break;
    case 'QUARTERLY':
      paymentPlan = 'common.paymentPlan.QUARTERLY'
      break;
    case 'MONTHLY':
      paymentPlan = 'common.paymentPlan.MONTHLY'
      break;
    case 'SINGLE':
      paymentPlan = 'common.paymentPlan.SINGLE'
      break;
    default:
      break;
  }
  return paymentPlan
}
// 获取保单货币
export const getCurrencyType	 = (value = '') => {
  // let currencyType = ''
  // switch (value) {
  //   case 'HKD':
  //     currencyType = 'common.currencyType.HKD'
  //     break;
  //   case 'MOP':
  //     currencyType = 'common.currencyType.MOP'
  //     break;
  //   case 'USD':
  //     currencyType = 'common.currencyType.USD'
  //     break;
  //   default:
  //     break;
  // }
  return value ? `common.currencyType.${value}` : ''
}

export const parseFloatValue = (num) => {
  const targetNum = num.replace(/,/g, '')
  var value = parseFloat(Number(targetNum)).toLocaleString()
  return value;
}
export const isFalsy = (value) => (value === 0 ? false : !value);

export const isVoid = (value) => value === undefined || value === null || value === "";
