import { lazy }from 'react'
const Home = lazy(() => import('../pages/home/Home'))
const Unauthorized = lazy(() => import('@/pages/unauthorized/Unauthorized'))
const ResetPassword = lazy(() => import('../pages/unauthorized/components/reset-password/ResetPassword'))
const RevisePassword = lazy(() => import('../pages/revise-password/RevisePassword'))
const NotFound = lazy(() => import('../pages/notfound/NotFound'))
const PrivacyPolicy = lazy(() => import('../pages/privacy-policy/PrivacyPolicy'))
const TermOfUse = lazy(() => import('../pages/term-of-use/TermOfUse'))
const Preferences = lazy(() => import('../pages/Preferences/Preferences'))
const ChangeLanguage = lazy(() => import('../pages/ChangeLanguage/index'))
const ProductList = lazy(() => import('../pages/Product/List/index'))
const ProductDetail = lazy(() => import('../pages/Product/Detail/index'))
const ProspectusList = lazy(() => import('../pages/Prospectus/List/index'))
const ProspectusDetail = lazy(() => import('../pages/Prospectus/Detail/index'))
const EditProspectus = lazy(() => import('../pages/Prospectus/Edit/index'))
export const  routes = [
  { 
    path: "/", 
    name: "App", 
    component: Home, 
    exact: true, 
    auth: true
  },
  { 
    path: "/login", 
    name: "Login", 
    component: Unauthorized, 
    exact: false, 
    auth: false
  },
  { 
    path: "/reset-password", 
    name: "ResetPassword", 
    component: ResetPassword, 
    exact: false, 
    auth: false
  },
  { 
    path: "/revise-password", 
    name: "ResetPassword", 
    component: RevisePassword, 
    exact: false, 
    auth: true
  },
  { 
    path: "/preferences", 
    name: "Preferences", 
    component: Preferences, 
    exact: false, 
    auth: true
  },
  { 
    path: "/change-language", 
    name: "ChangeLanguage", 
    component: ChangeLanguage, 
    exact: false, 
    auth: true
  },
  { 
    path: "/products", 
    name: "ProductList", 
    component: ProductList, 
    exact: false, 
    auth: true
  },
  { 
    path: "/product/detail", 
    name: "ProductDetail", 
    component: ProductDetail, 
    exact: false, 
    auth: true
  },
  { 
    path: "/prospectus/list", 
    name: "ProspectusList", 
    component: ProspectusList, 
    exact: true, 
    auth: true
  },
  { 
    path: "/prospectus/detail", 
    name: "ProspectusDetail", 
    component: ProspectusDetail, 
    exact: true, 
    auth: true
  },
  { 
    path: "/prospectus/edit", 
    name: "EditProspectus", 
    component: EditProspectus, 
    exact: true, 
    auth: true
  },
  { 
    path: "/privacy-policy", 
    name: "PrivacyPolicy", 
    component: PrivacyPolicy, 
    exact: false, 
    auth: false
  },
  { 
    path: "/terms-of-use", 
    name: "TermOfUse", 
    component: TermOfUse, 
    exact: false, 
    auth: false
  },
  { 
    path: "/404", 
    name: "NotFound", 
    component: NotFound, 
    exact: false, 
    auth: false
  }
]