import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { K_LOGIN_TOKEN,  } from "../../utils/constants"
import { getStore } from "../../utils"
import { getUserInfo } from '../../services/api'
const initialState = {
  data: {
    user: {
      status: '', 
      name: '', 
      avatar: '', 
      userNo: '',
      contentLanguage: ''
    },
    permissions: [],
    roles: []
  },
  token: getStore(K_LOGIN_TOKEN) || '',
  loading: false,
  errMsg: ''
}

export const fetchUserInfo = createAsyncThunk(
  "userInfo/getUserInfo",
  async () => await getUserInfo()
)

export const userSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    }
  },
  extraReducers: {
    [fetchUserInfo.pending.type]: (state) => {
      state.loading = true
    },
    [fetchUserInfo.fulfilled.type]: (state, action) => {
      state.data = action.payload.data || state.data
      if (action.payload.code === 401) {
        state.token = ''
      }
      state.errMsg = action.payload.code === 200 ? '' : action.payload.msg
      state.loading = false
    },
    [fetchUserInfo.rejected.type]: (state, action) => {
      state.loading = false
      state.errMsg = action.payload
    }
  }
})

export const { setToken } = userSlice.actions