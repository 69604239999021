import request from './request'
import { getStore } from '../utils'
import { K_LOGIN_TOKEN, K_LOCAL_SETTINGS_LANGUAGE } from '../utils/constants'
// const headers = {
//   'Authorization': getStore(K_LOGIN_TOKEN).length > 0 ? ('Bearer' + getStore(K_LOGIN_TOKEN)) : '',
//   'content-language': getStore(K_LOCAL_SETTINGS_LANGUAGE) || 'zh_CN'
// }
const http = {
  /**
   * methods: 请求
   * @param url 请求地址
   * @param params 请求参数
   */
  get (url, params = {}) {
    const config = {
      method: 'get',
      url: url,
      params,
      headers: {
        'Authorization': getStore(K_LOGIN_TOKEN) ? ('Bearer ' + getStore(K_LOGIN_TOKEN)) : '',
        'content-language': getStore(K_LOCAL_SETTINGS_LANGUAGE) || 'zh_CN'
      }
    }
    return request(config)
  },
  post (url, params = {}, header) {
    const config = {
      method: 'post',
      url: url,
      data: params,
      headers: {
        'Authorization': url === 'login' ? '' : getStore(K_LOGIN_TOKEN) ? ('Bearer ' + getStore(K_LOGIN_TOKEN)) : '',
        'content-language': getStore(K_LOCAL_SETTINGS_LANGUAGE) || 'zh_CN'
      }
    }
    if (header) config.headers = {...config.headers, ...header}
    return request(config)
  }
}
export default http
