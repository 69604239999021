import axios from 'axios'
import { Toast } from 'antd-mobile'
import { setStore, removeItem } from '../utils'
import { K_LOGIN_TOKEN, K_LOCAL_SETTINGS_LANGUAGE } from '../utils/constants'
const baseUrl = process.env.REACT_APP_API_URL
const service = axios.create({
  // 公共接口--这里注意后面会讲
  baseURL: baseUrl,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 5*1000,
  headers: {
    'content-Type': 'application/json'
  }
})
// 2.请求拦截器
service.interceptors.request.use(config => {
  return config
}, e => {
  Promise.reject(e)
})
// 3.响应拦截器
service.interceptors.response.use(config => {
  const code = config.data.code
  if (code === 401) { //token失效
    removeItem(K_LOGIN_TOKEN)
    setStore(K_LOCAL_SETTINGS_LANGUAGE, 'zh_CN')
    window.location.href='/login'
  }
  return config.data
}, e => {
  Toast.show(e.message || '未知錯誤')
  return Promise.reject(e)
})
export default service
