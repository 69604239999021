import React from 'react'
import { Route, Redirect, useLocation } from "react-router-dom"
import { K_LOGIN_TOKEN } from './utils/constants'
import { getStore } from './utils'
export function FrontendAuth (props) {
  const location = useLocation()
  const { routerConfig } = props
  const { pathname } = location
  const isLogin = getStore(K_LOGIN_TOKEN)
  const targetRouterConfig = routerConfig.find(
    (item) => {
      const path = item.path.replace(/\s*/g,"")
      if (path.indexOf('/:') !== -1 && item.alias) {
        return  pathname.indexOf(item.alias) !== -1
      }
      return path === pathname
    }
  )
  
  if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
    const { exact, path,component } = targetRouterConfig
    return <Route exact={exact} path={path} component={component} />
  }
  if (isLogin) {
    // 如果是登陆状态，想要跳转到登陆，重定向到主页
    if (pathname === "/login") {
      return <Redirect to="/" />;
    } else {
      // 如果路由合法，就跳转到相应的路由
      if (targetRouterConfig) {
        const { exact, path, component } = targetRouterConfig
        return (<Route exact={exact} path={path} component={component} />);
      } else {
        // 如果路由不合法，重定向到 404 页面
        return <Redirect to="/404" />;
      }
    }
  } else {
    // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
    if (targetRouterConfig && targetRouterConfig.auth) {
      return <Redirect to="/login" />;
    } else {
      // 非登陆状态下，路由不合法时，重定向至 404
      return <Redirect to="/404" />;
    }
  }
}