import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { localSettingsSlice } from './localSettings/slice'
import { userSlice } from './user/slice'
// combineReducers()
const rootReducer = combineReducers({
  localSettings: localSettingsSlice.reducer,
  user: userSlice.reducer
})
// 使用中间件的目的就是能派发一个函数的action
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
})
export default store
