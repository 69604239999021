import http from './http'
export const getUserInfo = () => {
  return http.get('getUserInfo')
}
export const login = (data) => {
  return http.post('login', data)
}
export const logout = (data) => {
  return http.post('logout', data)
}
export const sendSMS = (data) => {
  return http.post('sms/send', data)
}
export const revisePassword = (data) => {
  return http.post('user/profile/updatePwd', data)
}
export const awakenUser = (data) => {
  return http.post('/user/profile/awakenUser', data)
}
export async function updateLanguage() {
  return http.post('user/profile/updateLanguage')
}
// 获取保险品牌
export const getBrandList = () => {
  return http.get('sales/insurance/brand/list')
}
// 查询保险产品列表
export const getProductList = (data) => {
  return http.post('sales/insurance/product/list', data)
}
//获取保险产品详细信息
export const getProductDetail = (param) => {
  return http.get('sales/insurance/product/getDetails', param)
}

// 获取计划书列表
export const getProspectusList = (param) => {
  return http.get('sales/quotation/list', param)
}

// 获取计划书产品信息
export const getProspectusProduct = (param) => {
  return http.get('sales/quotation/getProduct', param)
}
// 获取计划书信息
export const getProspectusDetail = (param) => {
  return http.get('sales/quotation/getDetails', param)
}
// 添加计划书申请单
export const addProspectus = (data) => {
  return http.post('sales/quotation/insert', data)
}

// 撤回计划书申请单
export const recallProspectus = (data) => {
  return http.post('sales/quotation/recall', data)
}

// 修改计划书申请单
export const updateProspectus = (data) => {
  return http.post('sales/quotation/update', data)
}