import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import srore from './redux/store'
import 'antd-mobile/es/global'
import './i18n/configs'
import './index.css';
import './assets/font/font.css'
import App from './App.jsx';
ReactDOM.render(
  <React.StrictMode>
    <Provider store={srore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
