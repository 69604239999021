import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { K_LOCAL_SETTINGS_LANGUAGE } from "../utils/constants"
import { getStore } from "../utils"
import translation_zh_CN from "./zh-CN.json"
import translation_zh_HK from "./zh-HK.json";

const resources = {
  zh_CN: {
    translation: translation_zh_CN,
  },
  zh_HK: {
    translation: translation_zh_HK,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getStore(K_LOCAL_SETTINGS_LANGUAGE) || 'zh_CN',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
