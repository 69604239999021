import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { assetsUrl } from '../../utils/constants'
import styles from'./FullPageError.module.scss'
export function FullPageError({message}) {
  const { t } = useTranslation()
  const history = useHistory()
  console.log(history)
  return (
    <div className={styles['error-box']}>
      <img src={assetsUrl + 'images/mobile/icon_system_error.png'} alt="" />
      <p>{message || t('common.system-abnormal')}</p>
      {/* <Button onClick={() => history.replace('/')}>{t('common.back-home')}</Button> */}
    </div>
  )
}