import { createSlice } from "@reduxjs/toolkit";
import i18 from 'i18next'
import { K_LOCAL_SETTINGS_LANGUAGE } from "../../utils/constants"
import { getStore } from "../../utils"
const initialState = {
  language: getStore(K_LOCAL_SETTINGS_LANGUAGE) || 'zh_CN',
  mobileArea: '86',
}
export const localSettingsSlice = createSlice({
  name: "localSettings",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      i18.changeLanguage(action.payload)
      state.language = action.payload
    },
    changeMobileArea: (state, action) => {
      state.mobileArea = action.payload
    },
  }
})
export const { changeLanguage, changeMobileArea } = localSettingsSlice.actions