import React, { Suspense, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { BrowserRouter as Router, Switch} from "react-router-dom";
import { FrontendAuth } from './FrontendAuth'
import { routes } from './router/router'
import { setStore, getStore } from "./utils";
import { K_LOCAL_SETTINGS_LANGUAGE, K_LOGIN_LANGUAGE_COMPLETED } from "./utils/constants";
import { ErrorBoundary } from "./components/error-boundary/ErrorBoundary";
import { FullPageLoading } from "./components/full-page-loading/FullPageLoading";
import { fetchUserInfo } from "./redux/user/slice";
import { changeLanguage } from "./redux/localSettings/slice";
import './App.css';
export default function App() {
  const dispatch = useDispatch()
  const { token, data } = useSelector(state => state.user)
  const { user } = data || {}
  // const [language, setLanguage] = useState(user.contentLanguage || 'zh_CN')
  useEffect(() => {
    token && dispatch(fetchUserInfo())
  }, [dispatch, token])
  useEffect(() => {
    const localLanguageComplete = getStore(K_LOGIN_LANGUAGE_COMPLETED)
    const language = user.contentLanguage
    // 改变语言两种场景：1. 登录成功获取用户信息 2. 偏好设置更改语言
    if (!localLanguageComplete && language) {
      setStore(K_LOCAL_SETTINGS_LANGUAGE, language)
      setStore(K_LOGIN_LANGUAGE_COMPLETED, language)
      dispatch(changeLanguage(language))
    }
  }, [dispatch, user])
  return (
    <div className="App">
      <Router>
        <ErrorBoundary>
          <Suspense fallback={<FullPageLoading />}>
            <Switch>
              <FrontendAuth routerConfig={routes} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </div>
  );
}
